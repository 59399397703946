import {
    defineMessages
} from 'react-intl';

export const errorMessages = defineMessages({
    "Success": {
        "id": "API.Success",
        "defaultMessage": "Success"
    },
    "Error": {
        "id": "API.Error",
        "defaultMessage": "Error"
    },
    "AFF00013": {
        "id": "API.Login.AFF00013",
        "defaultMessage": "Affiliate Code is not found"
    },
    "AFF00021": {
        "id": "API.Login.AFF00021",
        "defaultMessage": "The account is not active"
    },
    "AFF00022": {
        "id": "API.Login.AFF00022",
        "defaultMessage": "The account is being locked"
    },
    "AFF00023": {
        "id": "API.Login.AFF00023",
        "defaultMessage": "The username and password do not match"
    },
    "AFF00025": {
        "id": "API.Login.AFF00025",
        "defaultMessage": "The Affiliate's application had not been approved yet"
    },
    "AFF15004": {
        "id": "API.ChangePassword.AFF15004",
        "defaultMessage": "Existing Password Unmatched"
    },
    "AFF29201": {
        "id": "API.SignUp.AFF29201",
        "defaultMessage": "Information not valid" // email already exist
    },
    "AFF29202": {
        "id": "API.SignUp.AFF29202",
        "defaultMessage": "USERNAME already exist"
    },
    "AFF29204": {
        "id": "API.SignUp.AFF29204",
        "defaultMessage": "Real Name already exist"   // FULLNAME already existed.
    },
    "AFF29203": {
        "id": "API.SignUp.AFF29203",
        "defaultMessage": "Information Not Valid" // phone number already exist
    },
    "AFF00027": {
        "id": "API.ForgotPassword.AFF00027",
        "defaultMessage": "Account not found"
    },
    "AFF23004": {
        "id": "API.ErrorCodes.AFF23004",
        "defaultMessage": "Add cash card failed"
    },
    "AFF23008": {
        "id": "API.ErrorCodes.AFF23008",
        "defaultMessage": "Insufficient balance"
    },
    "AFF23010": {
        "id": "API.ErrorCodes.AFF23010",
        "defaultMessage": "Amount can't exceed the max amount"
    },
    "AFF23999": {
        "id": "API.ErrorCodes.AFF23999",
        "defaultMessage": "Cash card function has been disabled. Please contact Customer Service."
    },
    "AFF24001": {
        "id": "API.ErrorCodes.AFF24001",
        "defaultMessage": "Withdrawal ID is required."
    },
    "AFF24002": {
        "id": "API.ErrorCodes.AFF24002",
        "defaultMessage": "Invalid status update"
    },
    "AFF24003": {
        "id": "API.ErrorCodes.AFF24003",
        "defaultMessage": "Withdrawal status update failed "
    },
    "AFF24006": {
        "id": "API.ErrorCodes.AFF24006",
        "defaultMessage": "Withdrawal request has been denied. Please contact customer service"
    },
    "AFF24011": {
        "id": "API.ErrorCodes.AFF24011",
        "defaultMessage": "Minimum Withdrawal amount is {0} {1}"
    },
    "AFF24012": {
        "id": "API.ErrorCodes.AFF24012",
        "defaultMessage": "Maximum Withdrawal amount is {0} {1}"
    },
    "AFF24013": {
        "id": "API.ErrorCodes.AFF24013",
        "defaultMessage": "Insufficient balance, please check your account details"
    },
    "AFF24014": {
        "id": "API.ErrorCodes.AFF24014",
        "defaultMessage": "Maximum withdrawal request is 10. Please wait for pending withdrawal to be processed or you may delete previous withdrawal request"
    },
    "AFF24015": {
        "id": "API.ErrorCodes.AFF24015",
        "defaultMessage": "Withdrawal amount not found."
    },
    "AFF24999": {
        "id": "API.ErrorCodes.AFF24999",
        "defaultMessage": "Transfer to member function has been denied. Please contact customer service."
    },
    "AFF25000": {
        "id": "API.ErrorCodes.AFF25000",
        "defaultMessage": "OTP Verification Success."
    },
    "AFF25001": {
        "id": "API.ErrorCodes.AFF25001",
        "defaultMessage": "OTP Expired."
    },
    "AFF25002": {
        "id": "API.ErrorCodes.AFF25002",
        "defaultMessage": "OTP exceeds attempt limit."
    },
    "AFF25003": {
        "id": "API.ErrorCodes.AFF25003",
        "defaultMessage": "Invalid OTP."
    },
    "AFF25004": {
        "id": "API.ErrorCodes.AFF25004",
        "defaultMessage": "Successfully resend OTP at {0}."
    },
    "AFF25005": {
        "id": "API.ErrorCodes.AFF25005",
        "defaultMessage": "OTP will be resend in every 2 minutes. Please try again later"
    },
    "AFF25006": {
        "id": "API.ErrorCodes.AFF25006",
        "defaultMessage": "Successfully re-verify OTP."
    },
    "AFF25007": {
        "id": "API.ErrorCodes.AFF25007",
        "defaultMessage": "You have failed to verify OTP for more than 3 times. Account is locked, kindly contact our customer service"
    },
    "AFF25008": {
        "id": "API.ErrorCodes.AFF25008",
        "defaultMessage": "OTP generated"
    },
    "AFF25009": {
        "id": "API.ErrorCodes.AFF25009",
        "defaultMessage": "Account is locked. Please contact our customer service"
    },
    "AFF25011": {
        "id": "API.ErrorCodes.AFF25011",
        "defaultMessage": "Contact Not Found."
    },
    "AFF25012": {
        "id": "API.ErrorCodes.AFF25012",
        "defaultMessage": "Failed to Add OTP Status."
    },
    "AFF25999": {
        "id": "API.ErrorCodes.AFF25999",
        "defaultMessage": "Unexpected Error. Please contact customer service."
    },
    "AFF29101": {
        "id": "API.ErrorCodes.AFF29101",
        "defaultMessage": "Email format invalid "
    },
    "AFF29102": {
        "id": "API.ErrorCodes.AFF29102",
        "defaultMessage": "Full name format invalid"
    },
    "AFF29103": {
        "id": "API.ErrorCodes.AFF29103",
        "defaultMessage": "Password format invalid"
    },
    "AFF29104": {
        "id": "API.ErrorCodes.AFF29104",
        "defaultMessage": "Confirm password is different from new password"
    },
    "AFF29105": {
        "id": "API.ErrorCodes.AFF29105",
        "defaultMessage": "Prefix not valid."
    },
    "AFF29106": {
        "id": "API.ErrorCodes.AFF29106",
        "defaultMessage": "Invalid Contact Length."
    },
    "AFF29107": {
        "id": "API.ErrorCodes.AFF29107",
        "defaultMessage": "Username format invalid"
    },
    "AFF29108": {
        "id": "API.ErrorCodes.AFF29108",
        "defaultMessage": "Password update failed"
    },
    "AFF30997": {
        "id": "API.ErrorCodes.AFF30997",
        "defaultMessage": "Existed bank "
    },
    "AFF30998": {
        "id": "API.ErrorCodes.AFF30998",
        "defaultMessage": "Bank Account Not Found."
    },
    "AFF30999": {
        "id": "API.ErrorCodes.AFF30999",
        "defaultMessage": "You have exceeded 10 bank accounts. Please delete inactive bank account to add new bank account "
    },
    "AFF31001": {
        "id": "API.ErrorCodes.AFF31001",
        "defaultMessage": "Commission is more than given rate"
    },
    "AFF32001": {
        "id": "API.ErrorCodes.AFF32001",
        "defaultMessage": "Failed to Delete Transaction History."
    },
    "AFF33001": {
        "id": "API.ErrorCodes.AFF33001",
        "defaultMessage": "The wallet name has been taken."
    },
    "AFF33002": {
        "id": 'API.ErrorCodes.AFF33002',
        "defaultMessage": "The wallet address has been taken."
    },
    "AFF33999": {
        "id": "API.ErrorCodes.AFF33999",
        "defaultMessage": "Unexpected Error. Please contact customer service."
    },
    "AFF36001": {
        "id": "API.ErrorCodes.AFF36001",
        "defaultMessage": "The receiver name has been taken."
    },
    "AFF36002": {
        "id": 'API.ErrorCodes.AFF36002',
        "defaultMessage": "The receiver phone number has been taken."
    },
    "AFF36003": {
        "id": "API.ErrorCodes.AFF36003",
        "defaultMessage": "You have exceeded 10 cash receivers."
    },
    "AFF36999": {
        "id": "API.ErrorCodes.AFF36999",
        "defaultMessage": "Unexpected Error. Please contact customer service."
    },
    "AFF25013": {
        "id": "API.ErrorCodes.AFF25009", //same as AFF25009
        "defaultMessage": "Account is locked. Please contact our customer service"
    },
    "AFF25014": {
        "id": "API.ErrorCodes.AFF25014",
        "defaultMessage": "Phone OTP attempted exceeded 3 times, we will now send the otp to your email instead. Please check your email"
    },
    "AFF25015": {
        "id": "API.ErrorCodes.AFF25015",
        "defaultMessage": "Email OTP attempted exceeded 3 times, we will now send the otp to your phone instead. Please check your phone"
    },
    "AFF26000": {
        "id": "API.ErrorCodes.AFF26000",
        "defaultMessage": "Sub Tracker Name cannot be blank"
    },
    "AFF26001": {
        "id": "API.ErrorCodes.AFF26001",
        "defaultMessage": "Sub tracker name length exceeded"
    },
    "AFF26002": {
        "id": "API.ErrorCodes.AFF26002",
        "defaultMessage": "Sub tracker name format invalid"
    },
    "AFF26003": {
        "id": "API.ErrorCodes.AFF26003",
        "defaultMessage": "Sub tracker description length exceeded"
    },
    "AFF26004": {
        "id": "API.ErrorCodes.AFF26004",
        "defaultMessage": "Sub tracker description format invalid"
    },
    "AFF29207": {
        "id": "ParentAffiliate.FormValidation.ParentAffiliateCodeNotValid",
        "defaultMessage": "This information invalid, please double check with your refferer"
    },
    "Withdrawal_AdminCostDeactivated": {
        "id": "Withdrawal.AdminCostDeactivated",
        "defaultMessage": "The currency, {0} is not available now. Please choose another currency."
    },
    "OtherCountryWrongFormat": {
        "id": "Bank.OtherCountryWrongFormat",
        "defaultMessage": "Please enter correct country name"
    }
})